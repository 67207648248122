import moment from "moment";
import { Row } from "react-table";
import { FinancialReportDetail } from "../generatedTypes";

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const truncateNumberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export function fieldChangeCase(
  initValue: object,
  updateState: (val: object) => void,
  eventTarget: EventTarget & HTMLInputElement,
  targetCase: "lower" | "upper",
  whitespace: boolean = true
) {
  let field = document.getElementById(eventTarget.id) as HTMLInputElement;
  let cursorLocation = eventTarget.selectionStart;
  let currentValue = field.value;
  let newValue;
  if (targetCase === "lower") {
    newValue = currentValue.toLowerCase();
  } else {
    newValue = currentValue.toUpperCase();
  }
  if (!whitespace) {
    const reg = new RegExp(/^(\S*)$/);
    if (reg.test(newValue) === true) {
      updateState({
        ...initValue,
        [eventTarget.id]: newValue,
      });
      field.value = newValue;
    }
  } else {
    updateState({
      ...initValue,
      [eventTarget.id]: newValue,
    });
    field.value = newValue;
  }

  field.setSelectionRange(cursorLocation, cursorLocation);
}

interface LoginTokens {
  id: string;
  ExpiresIn: number;
  RefreshToken: string;
}

export const authStorage = {
  getLoginTokens() {
    const user = localStorage.getItem("user") || "{}";
    return JSON.parse(user) as LoginTokens;
  },
  getAccessToken() {
    const token = localStorage.getItem("accessToken") || "";
    return JSON.parse(token);
  },
  setLoginTokens(loginTokens: LoginTokens) {
    localStorage.setItem("user", JSON.stringify(loginTokens));
  },
  removeLoginTokens() {
    localStorage.removeItem("user");
  },
};

export const tryParseInt = (str: string, defaultValue: any) => {
  var retValue = defaultValue;
  if (str !== null) {
    if (str.length > 0) {
      if (!isNaN(parseInt(str))) {
        retValue = parseInt(str);
      }
    }
  }
  return retValue;
};

export const tryParseFloat = (str: string, defaultValue: any) => {
  var retValue = defaultValue;
  if (str !== null) {
    if (str.length > 0) {
      if (!isNaN(parseFloat(str))) {
        retValue = parseFloat(str);
      }
    }
  }
  return retValue;
};

export function integerRangeFilter(
  rows: Array<Row>,
  columnId: string,
  filterValue: string
): Row[] {
  return rows.filter((row) => {
    const filterValueArray = filterValue
      .split(",")
      .map((x) => tryParseInt(x, null));
    const min = filterValueArray[0];
    const max = filterValueArray[1];

    if (min && max) {
      return row.values[columnId] >= min && row.values[columnId] <= max;
    } else if (min && !max) {
      return row.values[columnId] >= min;
    } else if (!min && max) {
      return row.values[columnId] <= max;
    }
    return null;
  });
}

export function decimalRangeFilter(
  rows: Array<Row>,
  columnId: string,
  filterValue: string
): Row[] {
  return rows.filter((row) => {
    const filterValueArray = filterValue
      .split(",")
      .map((x) => tryParseFloat(x, null));
    const min = filterValueArray[0];
    const max = filterValueArray[1];

    if (min && max) {
      return row.values[columnId] >= min && row.values[columnId] <= max;
    } else if (min && !max) {
      return row.values[columnId] >= min;
    } else if (!min && max) {
      return row.values[columnId] <= max;
    }
    return null;
  });
}

export function dateRangeFilter(
  rows: Array<Row>,
  columnId: string,
  filterValue: string
): Row[] {
  return rows.filter((row) => {
    const filterValueArray = filterValue
      .split(",")
      .map((x) => moment(x).format("MM/DD/yy"));
    const min =
      filterValueArray[0] === "Invalid date" ? null : filterValueArray[0];
    const max =
      filterValueArray[1] === "Invalid date" ? null : filterValueArray[1];

    if (min && max) {
      return (
        moment(row.values[columnId]).isAfter(min) &&
        moment(row.values[columnId]).isBefore(max)
      );
    } else if (min && !max) {
      return moment(row.values[columnId]).isAfter(min);
    } else if (!min && max) {
      return moment(row.values[columnId]).isBefore(max);
    }
    return null;
  });
}
export function formatNumber(num: number): string {
  if (num > 999) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num.toString();
}

export const getSortedDates = (details: FinancialReportDetail[]) => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const rangeStart = `${previousYear}-01-01T00:00:00`;
  const rangeEnd = `${currentYear}-12-31T00:00:00`;
  const filtered = details.filter(
    (entry: FinancialReportDetail) =>
      entry.date && entry.date >= rangeStart && entry.date <= rangeEnd
  );

  return filtered?.sort(
    (a: FinancialReportDetail, b: FinancialReportDetail) =>
      new Date(a.date ?? "").getTime() - new Date(b.date ?? "").getTime()
  );
};

export const getSortedYtd = (details: FinancialReportDetail[]) => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const prevYearRangeStart = `${previousYear}-01-01T00:00:00`;
  const prevYearRangeEnd = `${previousYear}-12-31T00:00:00`;
  const currentYearRangeStart = `${currentYear}-01-01T00:00:00`;
  const currentYearRangeEnd = `${currentYear}-12-31T00:00:00`;
  const prevYearRange = details.filter(
    (item: FinancialReportDetail) =>
      item.date &&
      item.date >= prevYearRangeStart &&
      item.date <= prevYearRangeEnd
  );
  const currentYearRange = details.filter(
    (item: FinancialReportDetail) =>
      item.date &&
      item.date >= currentYearRangeStart &&
      item.date <= currentYearRangeEnd
  );

  const accumulateYTD = (data: FinancialReportDetail[]) => {
    let cumulativeYTD = 0;
    return data.map((item) => {
      cumulativeYTD += item.pointTotal ?? 0;
      return { ...item, ytd: cumulativeYTD };
    });
  };

  const newPreviousYearArray = accumulateYTD(prevYearRange);
  const newCurrentYearArray = accumulateYTD(currentYearRange);

  return [...newPreviousYearArray, ...newCurrentYearArray];
};
