import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "./context/Auth";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(
  () => import("./styles/components/Unauthenticated/UnauthenticatedApp")
);

function App() {
  const { isAuthenticated, refetchToken, logout } = useAuth();

  useEffect(() => {
    let refetchTokenQuarterHour: NodeJS.Timer;

    const handleTokens = async () => {
      const authToken = localStorage.getItem("authToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken && !authToken) return () => null;

      try {
        await refetchToken();
        console.log("[APP-NAV] refetching token && starting timer");
        refetchTokenQuarterHour = await setInterval(async () => {
          console.log("[APP-NAV] Refetching token - from timer");
          await refetchToken();
        }, 1000 * 60 * 15);
      } catch (error: any) {
        toast.error(error.message, {
          autoClose: false,
          containerId: "standard",
        });
        logout();
      }
    };

    handleTokens();

    return () => {
      clearInterval(refetchTokenQuarterHour);
    };
  }, [logout, refetchToken]);

  return (
    <div>
      <React.Suspense>
        {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </div>
  );
}

export default App;
