import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
// import { useAuth } from "./context/Auth";
import { useConfirm } from "./context/Confirm";

export const QueryWrapper = ({ children }: any) => {
  const { handleSetConfirmProps } = useConfirm();
  // const { logout } = useAuth();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        onError: (error: any) => {
          var errorMessage = error?.error
            ? error?.error
            : error?.message !== "[object Response]"
            ? error.message
            : "Looks like something went wrong!";

          handleSetConfirmProps({
            title: "Error",
            message: errorMessage,
          });
          // logout();
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
