import React, { FC, useState } from "react";
import { login, refreshAccessToken } from "../libs/db-lib";
import { User, USER_TYPE } from "../types";
import { toast } from "react-toastify";

type AuthContextProviderType = {
  isAuthenticated: boolean;
  handleLogin: (form: { username: string; password: string }) => void;
  logout: () => void;
  refetchToken: () => void;
  user: User | null;
};

const authContext: AuthContextProviderType = {
  isAuthenticated: false,
  handleLogin: () => {},
  logout: () => {},
  refetchToken: () => {},
  user: null,
};

const AuthContext = React.createContext(authContext);
AuthContext.displayName = "AuthContext";

export const AuthProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    authContext.isAuthenticated
  );
  const [user, setUser] = useState<User | null>(null);

  React.useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsAuthenticated(true);
      const localUser = JSON.parse(localStorage.getItem("user") || "{}");
      setUser(localUser);
    }
  }, [setIsAuthenticated]);

  const handleLogin = async (form: { username: string; password: string }) => {
    await login({ username: form.username, password: form.password })
      .then((response: any) => {
        localStorage.setItem("accessToken", JSON.stringify(response.token));
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(response.refreshToken)
        );
        const receivedUser: User = {
          id: response.userId,
          userName: response.userName,
          firstName: "",
          lastName: "",
          email: "",
        };
        localStorage.setItem("user", JSON.stringify(receivedUser));
        setUser(receivedUser);
        setIsAuthenticated(true);
      })
      .catch((error: any) => {
        var errorMessage =
          error?.message !== "[object Response]"
            ? error.message
            : "Login failed";
        toast.error(errorMessage, {
          autoClose: false,
          containerId: "standard",
        });
      });
  };

  const refetchToken = async () => {
    const accessToken = JSON.parse(
      localStorage.getItem("accessToken") as string
    );
    const refreshToken = JSON.parse(
      localStorage.getItem("refreshToken") as string
    );
    if (!accessToken || !refreshToken) logout();

    await refreshAccessToken({ accessToken, refreshToken })
      .then((response: any) => {
        localStorage.setItem("accessToken", JSON.stringify(response));
      })
      .catch((error: any) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: "standard",
        });
        logout();
      });
  };

  const logout = () => {
    console.log("logging out");
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("user");
    setIsAuthenticated(false);
  };

  const provider = {
    isAuthenticated,
    handleLogin,
    logout,
    refetchToken,
    user,
  };

  return (
    <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { useAuth };
