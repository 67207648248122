/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiBadRequestResponse {
  message?: string | null;
}

export interface ApiNotFoundRequestResponse {
  message?: string | null;
}

export interface BasicUserResponse {
  /** @format uuid */
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
  email?: string | null;
  phone?: string | null;
}

export type BreedingDetail = BreedingListing & {
  inseminationLocation?: string | null;
  isPregnant?: boolean | null;
  notes?: string | null;
};

export interface BreedingListing {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  inseminationAt?: string | null;

  /** @format date-time */
  pregnancyCheckAt?: string | null;

  /** @format date-time */
  flushAt?: string | null;

  /** @format date-time */
  closedAt?: string | null;
  inseminationType?: BreedingTransferMethod;

  /** @format int32 */
  embryoCount?: number;
  embryos?: (EmbryoListing | EmbryoDetail)[] | null;
}

export interface BreedingListingPaginationResult {
  /** @format int32 */
  totalCount?: number;

  /** @format int32 */
  filteredCount?: number;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  page?: number;
  results?: (BreedingListing | BreedingDetail)[] | null;
  query?:
    | PaginationQuery
    | EmbryoListingQuery
    | ListingQuery
    | RecipListingQuery
    | CowListingQuery
    | BreedingListingQuery
    | null;
}

export type BreedingListingQuery = PaginationQuery & { cowId: string };

export interface BreedingLogEmbryoEntry {
  /** @format uuid */
  id?: string | null;
  name: string;
}

export interface BreedingRequest {
  /** @format date-time */
  inseminationAt?: string | null;
  inseminationLocation?: string | null;
  inseminationType?: BreedingTransferMethod;

  /** @format date-time */
  pregnancyCheckAt?: string | null;
  isPregnant?: boolean | null;

  /** @format date-time */
  flushAt?: string | null;
  notes?: string | null;
  embryos?: BreedingLogEmbryoEntry[] | null;
}

export enum BreedingResult {
  Other = "Other",
  Healthy = "Healthy",
  Deceased = "Deceased",
}

export enum BreedingTransferMethod {
  Other = "Other",
  IVFFresh = "IVFFresh",
  IVFFrozen = "IVFFrozen",
}

export type CowDetail = CowListing & {
  registration?: string | null;
  sireId?: string | null;
  sire?: string | null;
  recipId?: string | null;
  recip?: string | null;
  commission?: number | null;
  foodAmount?: number;
  healthcareAmount?: number;
  breedingAmount?: number;
  otherAmount?: number;
  totalExpenses?: number;
  purchaseLocation?: string | null;
  soldLocation?: string | null;
  offspring?: OffspringListing[] | null;
  latestBreedingEvent?: BreedingDetail;
  notes?: string | null;
};

export interface CowListing {
  /** @format uuid */
  id?: string;
  qboId?: string | null;
  tag?: string | null;
  name?: string | null;
  cowType?: CowType;

  /** @format date-time */
  bornAt?: string | null;

  /** @format date-time */
  deathAt?: string | null;

  /** @format date-time */
  purchasedAt?: string | null;

  /** @format double */
  purchaseAmount?: number | null;
  purchasedFrom?: string | null;

  /** @format date-time */
  soldAt?: string | null;
  soldTo?: string | null;

  /** @format double */
  soldAmount?: number | null;

  /** @format uuid */
  damId?: string | null;
  damType?: CowType;
  dam?: string | null;

  /** @format double */
  projectTotal?: number;

  /** @format double */
  grandTotal?: number;
}

export interface CowListingPaginationResult {
  /** @format int32 */
  totalCount?: number;

  /** @format int32 */
  filteredCount?: number;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  page?: number;
  results?: (CowListing | CowDetail | RecipDetail)[] | null;
  query?:
    | PaginationQuery
    | EmbryoListingQuery
    | ListingQuery
    | RecipListingQuery
    | CowListingQuery
    | BreedingListingQuery
    | null;
}

export type CowListingQuery = RecipListingQuery & { cowType?: CowType };

export interface CowReportDetail {
  label?: string | null;

  /** @format double */
  value?: number;
}

export interface CowReportTopBottom {
  topResults?: CowReportDetail[] | null;
  bottomResults?: CowReportDetail[] | null;
}

export enum CowType {
  Bull = "Bull",
  Heifer = "Heifer",
  Cow = "Cow",
  Recip = "Recip",
}

export interface CowUpdateRequest {
  name: string;
  cowType: CowType;

  /** @format date-time */
  bornAt?: string | null;

  /** @format date-time */
  deathAt?: string | null;

  /** @format date-time */
  purchasedAt?: string | null;

  /** @format double */
  purchaseAmount?: number | null;
  purchasedFrom?: string | null;

  /** @format date-time */
  soldAt?: string | null;

  /** @format double */
  soldAmount?: number | null;
  soldTo?: string | null;

  /** @format uuid */
  damId?: string | null;

  /** @format uuid */
  sireId?: string | null;

  /** @format uuid */
  recipId?: string | null;
  notes?: string | null;
}

export interface CreateUser {
  firstName: string;
  lastName: string;
  phone: string;

  /** @format email */
  email: string;

  /** @format password */
  password: string;
  role: string;
}

export type EmbryoDetail = EmbryoListing & {
  transferResult?: boolean | null;
  flushAt?: string | null;
  notes?: string | null;
  commission?: number | null;
  foodAmount?: number;
  healthcareAmount?: number;
  breedingAmount?: number;
  otherAmount?: number;
  totalExpenses?: number;
  purchasedFrom?: string | null;
  purchasedAt?: string | null;
  purchaseAmount?: number | null;
  purchaseLocation?: string | null;
  soldAt?: string | null;
  soldAmount?: number | null;
  soldTo?: string | null;
  soldLocation?: string | null;
  projectTotal?: number;
  grandTotal?: number;
};

export interface EmbryoListing {
  /** @format uuid */
  id?: string;
  qboId?: string | null;
  name?: string | null;

  /** @format uuid */
  damId?: string | null;
  damType?: CowType;
  dam?: string | null;

  /** @format uuid */
  sireId?: string | null;
  sire?: string | null;

  /** @format uuid */
  recipId?: string | null;
  recip?: string | null;

  /** @format date-time */
  frozenAt?: string | null;
  tank?: string | null;

  /** @format date-time */
  transferAt?: string | null;
  transferLocation?: string | null;
  transferType?: BreedingTransferMethod;
  inseminationType?: BreedingTransferMethod;

  /** @format date-time */
  transferCheckAt?: string | null;
  sex?: Sex;

  /** @format date-time */
  bornAt?: string | null;
  birthStatus?: BreedingResult;

  /** @format uuid */
  assetId?: string | null;
  assetType?: CowType;
  asset?: string | null;
}

export interface EmbryoListingPaginationResult {
  /** @format int32 */
  totalCount?: number;

  /** @format int32 */
  filteredCount?: number;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  page?: number;
  results?: (EmbryoListing | EmbryoDetail)[] | null;
  query?:
    | PaginationQuery
    | EmbryoListingQuery
    | ListingQuery
    | RecipListingQuery
    | CowListingQuery
    | BreedingListingQuery
    | null;
}

export type EmbryoListingQuery = ListingQuery & {
  minFrozenAt?: string | null;
  maxFrozenAt?: string | null;
  minTransferAt?: string | null;
  maxTransferAt?: string | null;
  minTransferCheckAt?: string | null;
  maxTransferCheckAt?: string | null;
  tank?: string | null;
  birthStatus?: BreedingResult;
  transferType?: BreedingTransferMethod;
};

export interface EmbryoUpdateRequest {
  name?: string | null;

  /** @format date-time */
  flushAt?: string | null;

  /** @format date-time */
  freezeAt?: string | null;
  tank?: string | null;

  /** @format date-time */
  transferAt?: string | null;
  transferLocation?: string | null;
  transferType?: BreedingTransferMethod;

  /** @format uuid */
  recipId?: string | null;

  /** @format date-time */
  transferCheckAt?: string | null;
  transferResult?: boolean | null;

  /** @format date-time */
  bornAt?: string | null;
  birthStatus?: BreedingResult;
  purchasedFrom?: string | null;

  /** @format date-time */
  purchasedAt?: string | null;

  /** @format double */
  purchaseAmount?: number | null;
  purchaseLocation?: string | null;

  /** @format uuid */
  sireId?: string | null;

  /** @format uuid */
  damId?: string | null;

  /** @format uuid */
  assetId?: string | null;
  sex?: Sex;
  notes?: string | null;
}

export enum ExpenseCategory {
  Other = "Other",
  Food = "Food",
  Healthcare = "Healthcare",
  Breeding = "Breeding",
}

export interface ExpenseListing {
  qboId?: string | null;
  category?: ExpenseCategory;

  /** @format date-time */
  expenseAt?: string;

  /** @format double */
  amount?: number;
  notes?: string | null;
}

export interface ExpenseListingPaginationResult {
  /** @format int32 */
  totalCount?: number;

  /** @format int32 */
  filteredCount?: number;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  page?: number;
  results?: ExpenseListing[] | null;
  query?:
    | PaginationQuery
    | EmbryoListingQuery
    | ListingQuery
    | RecipListingQuery
    | CowListingQuery
    | BreedingListingQuery
    | null;
}

export interface FinancialReportDetail {
  /** @format date-time */
  date?: string;

  /** @format int32 */
  month?: number | null;

  /** @format int32 */
  year?: number | null;

  /** @format double */
  pointTotal?: number;

  /** @format double */
  ytd?: number;

  /** @format double */
  cum?: number;
}

export interface IdentityError {
  code?: string | null;
  description?: string | null;
}

export interface IdentityResult {
  succeeded?: boolean;
  errors?: IdentityError[] | null;
}

export interface ListItem {
  name?: string | null;
  enumValue?: string | null;

  /** @format double */
  add?: number | null;

  /** @format double */
  amount?: number;
}

export type ListingQuery = PaginationQuery & {
  minPurchaseAt?: string | null;
  maxPurchaseAt?: string | null;
  minPurchaseAmount?: number | null;
  maxPurchaseAmount?: number | null;
  minBornAt?: string | null;
  maxBornAt?: string | null;
  minSoldAt?: string | null;
  maxSoldAt?: string | null;
  minSoldAmount?: number | null;
  maxSoldAmount?: number | null;
  minProjectTotal?: number | null;
  maxProjectTotal?: number | null;
  minGrandTotal?: number | null;
  maxGrandTotal?: number | null;
  purchasedFrom?: string | null;
  soldTo?: string | null;
  damId?: string | null;
  sireId?: string | null;
  recipId?: string | null;
};

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResult {
  /** @format uuid */
  userId?: string;
  name?: string | null;

  /** @format uuid */
  refreshToken?: string;
  token?: string | null;
  role?: string | null;
}

export interface OffspringListing {
  /** @format uuid */
  id?: string;
  name?: string | null;
}

export interface OkResult {
  /** @format int32 */
  statusCode?: number;
}

export interface OptionEnumRepsonse {
  value?: string | null;
  display?: string | null;
}

export interface OptionGuidResponse {
  /** @format uuid */
  id?: string;
  label?: string | null;
}

export interface PaginationQuery {
  /** @format int32 */
  page?: number;

  /**
   * @format int32
   * @min 1
   * @max 100
   */
  sizePerPage?: number;
  sortField?: string | null;
  sortOrder?: SortOrder;
  search?: string | null;
}

export interface PasswordResetRequest {
  /** @format uuid */
  userId: string;
  token?: string | null;
  password?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface RecipBreedingDetail {
  /** @format uuid */
  embryoId?: string | null;
  embryoName?: string | null;

  /** @format uuid */
  damId?: string | null;
  damName?: string | null;

  /** @format date-time */
  transferAt?: string | null;

  /** @format date-time */
  transferCheckAt?: string | null;
  transferLocation?: string | null;
  transferType?: BreedingTransferMethod;
  transferResult?: boolean | null;
  sex?: Sex;

  /** @format date-time */
  bornAt?: string | null;
  birthStatus?: BreedingResult;

  /** @format uuid */
  assetId?: string | null;
  assetName?: string | null;
}

export interface RecipBreedingRequest {
  /** @format uuid */
  damId?: string | null;

  /** @format date-time */
  transferAt?: string | null;
  transferLocation?: string | null;
  transferType?: BreedingTransferMethod;

  /** @format uuid */
  embryoId?: string | null;

  /** @format date-time */
  transferCheckAt?: string | null;
  transferResult?: boolean | null;

  /** @format date-time */
  bornAt?: string | null;
  birthStatus?: BreedingResult;
  sex?: Sex;

  /** @format uuid */
  assetId?: string | null;
}

export type RecipDetail = CowDetail & { latestBreedingEvent?: RecipBreedingDetail };

export interface RecipListing {
  /** @format uuid */
  id?: string;
  name?: string | null;

  /** @format uuid */
  embryoId?: string | null;
  embryoName?: string | null;

  /** @format date-time */
  transferAt?: string | null;

  /** @format date-time */
  transferCheckAt?: string | null;
  transferLocation?: string | null;
  transferType?: BreedingTransferMethod;
  transferResult?: boolean | null;
}

export interface RecipListingPaginationResult {
  /** @format int32 */
  totalCount?: number;

  /** @format int32 */
  filteredCount?: number;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  page?: number;
  results?: RecipListing[] | null;
  query?:
    | PaginationQuery
    | EmbryoListingQuery
    | ListingQuery
    | RecipListingQuery
    | CowListingQuery
    | BreedingListingQuery
    | null;
}

export type RecipListingQuery = ListingQuery & {
  minTransferAt?: string | null;
  maxTransferAt?: string | null;
  minTransferCheckAt?: string | null;
  maxTransferCheckAt?: string | null;
  transferType?: BreedingTransferMethod;
  embryoId?: string | null;
  transferLocation?: string | null;
  isPregnant?: boolean | null;
};

export interface RecipUpdateBreedingLog {
  /** @format uuid */
  embryoId?: string;

  /** @format uuid */
  damId?: string | null;

  /** @format date-time */
  transferAt?: string;
  transferLocation?: string | null;
  transferType?: BreedingTransferMethod;

  /** @format date-time */
  transferCheckAt?: string | null;
  transferResult?: boolean | null;

  /** @format date-time */
  bornAt?: string | null;
  birthStatus?: BreedingResult;
  sex?: Sex;

  /** @format uuid */
  assetId?: string | null;
}

export type RecipUpdateRequest = CowUpdateRequest & { breedingLogEntry?: RecipUpdateBreedingLog };

export interface RefreshRequest {
  token: string;

  /** @format uuid */
  refreshToken: string;
}

export interface ReportListing {
  sidebarStats?: SidebarItem[] | null;
  revenue?: FinancialReportDetail[] | null;
  expenses?: FinancialReportDetail[] | null;
  profit?: FinancialReportDetail[] | null;
  cowFlushCount?: CowReportTopBottom;
  cowPregnancySuccess?: CowReportTopBottom;
  recipsProfitability?: CowReportTopBottom;
  recipsPregnancySuccess?: CowReportTopBottom;
}

export enum Sex {
  Male = "Male",
  Female = "Female",
}

export interface SidebarItem {
  overview?: string | null;
  summary?: Summary;
}

export enum SortOrder {
  Ascending = "Ascending",
  Descending = "Descending",
}

export interface Summary {
  title?: string | null;
  subtitle?: string | null;

  /** @format double */
  total?: number;
  listItems?: ListItem[] | null;
}

export interface UpdateUser {
  /** @format uuid */
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;

  /** @format email */
  email?: string | null;
  role: string;
}

export interface UserAndRoles {
  /** @format uuid */
  userId?: string;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
  email?: string | null;
  phone?: string | null;
  role?: string | null;
}

export type UserResponse = BasicUserResponse & { role?: string | null };

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title AAA.CattleLog.API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  breeding = {
    /**
     * No description
     *
     * @tags Breeding
     * @name ByCowIdDetail
     * @request GET:/Breeding/ByCowId/{id}
     * @secure
     */
    byCowIdDetail: (
      id: string,
      query?: { page?: number; sizePerPage?: number; sortField?: string; sortOrder?: SortOrder; search?: string },
      params: RequestParams = {},
    ) =>
      this.request<BreedingListingPaginationResult, any>({
        path: `/Breeding/ByCowId/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Breeding
     * @name UpdateEntryCreate
     * @request POST:/Breeding/UpdateEntry/{id}
     * @secure
     */
    updateEntryCreate: (id: string, data: BreedingRequest, params: RequestParams = {}) =>
      this.request<BreedingListing | BreedingDetail, string>({
        path: `/Breeding/UpdateEntry/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Breeding
     * @name UpdateRecipEntryCreate
     * @request POST:/Breeding/UpdateRecipEntry/{id}
     * @secure
     */
    updateRecipEntryCreate: (id: string, data: RecipBreedingRequest, params: RequestParams = {}) =>
      this.request<RecipBreedingDetail, string>({
        path: `/Breeding/UpdateRecipEntry/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  qbo = {
    /**
     * No description
     *
     * @tags Callback
     * @name CallbackInitAuthList
     * @request GET:/QBO/Callback/InitAuth
     * @secure
     */
    callbackInitAuthList: (query?: { returnUrl?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/QBO/Callback/InitAuth`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Callback
     * @name CallbackList
     * @request GET:/QBO/Callback
     * @secure
     */
    callbackList: (query?: { code?: string; realmId?: number; state?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/QBO/Callback`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  cow = {
    /**
     * No description
     *
     * @tags Cow
     * @name ListingList
     * @request GET:/Cow/Listing
     * @secure
     */
    listingList: (
      query?: {
        cowType?: CowType;
        minTransferAt?: string;
        maxTransferAt?: string;
        minTransferCheckAt?: string;
        maxTransferCheckAt?: string;
        transferType?: BreedingTransferMethod;
        embryoId?: string;
        transferLocation?: string;
        isPregnant?: boolean;
        minPurchaseAt?: string;
        maxPurchaseAt?: string;
        minPurchaseAmount?: number;
        maxPurchaseAmount?: number;
        minBornAt?: string;
        maxBornAt?: string;
        minSoldAt?: string;
        maxSoldAt?: string;
        minSoldAmount?: number;
        maxSoldAmount?: number;
        minProjectTotal?: number;
        maxProjectTotal?: number;
        minGrandTotal?: number;
        maxGrandTotal?: number;
        purchasedFrom?: string;
        soldTo?: string;
        damId?: string;
        sireId?: string;
        recipId?: string;
        page?: number;
        sizePerPage?: number;
        sortField?: string;
        sortOrder?: SortOrder;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CowListingPaginationResult, any>({
        path: `/Cow/Listing`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cow
     * @name RecipListingList
     * @request GET:/Cow/RecipListing
     * @secure
     */
    recipListingList: (
      query?: {
        minTransferAt?: string;
        maxTransferAt?: string;
        minTransferCheckAt?: string;
        maxTransferCheckAt?: string;
        transferType?: BreedingTransferMethod;
        embryoId?: string;
        transferLocation?: string;
        isPregnant?: boolean;
        minPurchaseAt?: string;
        maxPurchaseAt?: string;
        minPurchaseAmount?: number;
        maxPurchaseAmount?: number;
        minBornAt?: string;
        maxBornAt?: string;
        minSoldAt?: string;
        maxSoldAt?: string;
        minSoldAmount?: number;
        maxSoldAmount?: number;
        minProjectTotal?: number;
        maxProjectTotal?: number;
        minGrandTotal?: number;
        maxGrandTotal?: number;
        purchasedFrom?: string;
        soldTo?: string;
        damId?: string;
        sireId?: string;
        recipId?: string;
        page?: number;
        sizePerPage?: number;
        sortField?: string;
        sortOrder?: SortOrder;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecipListingPaginationResult, any>({
        path: `/Cow/RecipListing`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cow
     * @name DetailsDetail
     * @request GET:/Cow/Details/{id}
     * @secure
     */
    detailsDetail: (id: string, params: RequestParams = {}) =>
      this.request<CowDetail | RecipDetail, any>({
        path: `/Cow/Details/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cow
     * @name UpdateCreate
     * @request POST:/Cow/Update/{id}
     * @secure
     */
    updateCreate: (id: string, data: CowUpdateRequest | RecipUpdateRequest, params: RequestParams = {}) =>
      this.request<CowDetail | RecipDetail, string>({
        path: `/Cow/Update/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cow
     * @name UpdateRecipCreate
     * @request POST:/Cow/UpdateRecip/{id}
     * @secure
     */
    updateRecipCreate: (id: string, data: RecipUpdateRequest, params: RequestParams = {}) =>
      this.request<RecipDetail, string>({
        path: `/Cow/UpdateRecip/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cow
     * @name MarkOpenCreate
     * @request POST:/Cow/MarkOpen/{id}
     * @secure
     */
    markOpenCreate: (id: string, params: RequestParams = {}) =>
      this.request<BreedingListing | BreedingDetail, any>({
        path: `/Cow/MarkOpen/${id}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cow
     * @name AddOffspringCreate
     * @request POST:/Cow/AddOffspring/{cowId}
     * @secure
     */
    addOffspringCreate: (cowId: string, query: { offspringId: string }, params: RequestParams = {}) =>
      this.request<CowDetail | RecipDetail, string>({
        path: `/Cow/AddOffspring/${cowId}`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cow
     * @name RemoveOffspringCreate
     * @request POST:/Cow/RemoveOffspring/{cowId}
     * @secure
     */
    removeOffspringCreate: (cowId: string, query: { offspringId: string }, params: RequestParams = {}) =>
      this.request<CowDetail | RecipDetail, string>({
        path: `/Cow/RemoveOffspring/${cowId}`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cow
     * @name UpdateCsvCreate
     * @request POST:/Cow/UpdateCSV
     * @secure
     */
    updateCsvCreate: (data: { csvUpload?: File }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Cow/UpdateCSV`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
  };
  embryo = {
    /**
     * No description
     *
     * @tags Embryo
     * @name ListingList
     * @request GET:/Embryo/Listing
     * @secure
     */
    listingList: (
      query?: {
        minFrozenAt?: string;
        maxFrozenAt?: string;
        minTransferAt?: string;
        maxTransferAt?: string;
        minTransferCheckAt?: string;
        maxTransferCheckAt?: string;
        tank?: string;
        birthStatus?: BreedingResult;
        transferType?: BreedingTransferMethod;
        minPurchaseAt?: string;
        maxPurchaseAt?: string;
        minPurchaseAmount?: number;
        maxPurchaseAmount?: number;
        minBornAt?: string;
        maxBornAt?: string;
        minSoldAt?: string;
        maxSoldAt?: string;
        minSoldAmount?: number;
        maxSoldAmount?: number;
        minProjectTotal?: number;
        maxProjectTotal?: number;
        minGrandTotal?: number;
        maxGrandTotal?: number;
        purchasedFrom?: string;
        soldTo?: string;
        damId?: string;
        sireId?: string;
        recipId?: string;
        page?: number;
        sizePerPage?: number;
        sortField?: string;
        sortOrder?: SortOrder;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmbryoListingPaginationResult, any>({
        path: `/Embryo/Listing`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Embryo
     * @name DetailsDetail
     * @request GET:/Embryo/Details/{id}
     * @secure
     */
    detailsDetail: (id: string, params: RequestParams = {}) =>
      this.request<EmbryoDetail, any>({
        path: `/Embryo/Details/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Embryo
     * @name UpdateCreate
     * @request POST:/Embryo/Update/{id}
     * @secure
     */
    updateCreate: (id: string, data: EmbryoUpdateRequest, params: RequestParams = {}) =>
      this.request<EmbryoDetail, string>({
        path: `/Embryo/Update/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  expense = {
    /**
     * No description
     *
     * @tags Expense
     * @name ByCowIdDetail
     * @request GET:/Expense/ByCowId/{id}
     * @secure
     */
    byCowIdDetail: (
      id: string,
      query?: { page?: number; sizePerPage?: number; sortField?: string; sortOrder?: SortOrder; search?: string },
      params: RequestParams = {},
    ) =>
      this.request<ExpenseListingPaginationResult, any>({
        path: `/Expense/ByCowId/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name ByEmbryoIdDetail
     * @request GET:/Expense/ByEmbryoId/{id}
     * @secure
     */
    byEmbryoIdDetail: (
      id: string,
      query?: { page?: number; sizePerPage?: number; sortField?: string; sortOrder?: SortOrder; search?: string },
      params: RequestParams = {},
    ) =>
      this.request<ExpenseListingPaginationResult, any>({
        path: `/Expense/ByEmbryoId/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  file = {
    /**
     * No description
     *
     * @tags File
     * @name UploadGemCreate
     * @request POST:/File/UploadGEM
     * @secure
     */
    uploadGemCreate: (data: { zipPackage: File }, params: RequestParams = {}) =>
      this.request<void, ApiBadRequestResponse>({
        path: `/File/UploadGEM`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
  };
  options = {
    /**
     * No description
     *
     * @tags Options
     * @name CowsList
     * @request GET:/Options/Cows
     * @secure
     */
    cowsList: (query?: { search?: string; cowType?: CowType; includeDeceased?: boolean }, params: RequestParams = {}) =>
      this.request<OptionGuidResponse[], any>({
        path: `/Options/Cows`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Options
     * @name EmbryosList
     * @request GET:/Options/Embryos
     * @secure
     */
    embryosList: (query?: { search?: string; includeTransfered?: boolean }, params: RequestParams = {}) =>
      this.request<OptionGuidResponse[], any>({
        path: `/Options/Embryos`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Options
     * @name BreedingResultsList
     * @request GET:/Options/BreedingResults
     * @secure
     */
    breedingResultsList: (params: RequestParams = {}) =>
      this.request<OptionEnumRepsonse[], any>({
        path: `/Options/BreedingResults`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Options
     * @name BreedingTransferMethodsList
     * @request GET:/Options/BreedingTransferMethods
     * @secure
     */
    breedingTransferMethodsList: (params: RequestParams = {}) =>
      this.request<OptionEnumRepsonse[], any>({
        path: `/Options/BreedingTransferMethods`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Options
     * @name TanksList
     * @request GET:/Options/Tanks
     * @secure
     */
    tanksList: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/Options/Tanks`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Options
     * @name PurchasedFromList
     * @request GET:/Options/PurchasedFrom
     * @secure
     */
    purchasedFromList: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/Options/PurchasedFrom`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Options
     * @name SoldToList
     * @request GET:/Options/SoldTo
     * @secure
     */
    soldToList: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/Options/SoldTo`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  report = {
    /**
     * No description
     *
     * @tags Report
     * @name GetFinancialsList
     * @request GET:/Report/GetFinancials
     * @secure
     */
    getFinancialsList: (params: RequestParams = {}) =>
      this.request<ReportListing, ProblemDetails>({
        path: `/Report/GetFinancials`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags User
     * @name AllUsersList
     * @request GET:/User/AllUsers
     * @secure
     */
    allUsersList: (params: RequestParams = {}) =>
      this.request<UserAndRoles[], ProblemDetails | ApiNotFoundRequestResponse>({
        path: `/User/AllUsers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserByIdList
     * @request GET:/User/UserById
     * @secure
     */
    userByIdList: (query?: { id?: string }, params: RequestParams = {}) =>
      this.request<UserAndRoles, ProblemDetails | ApiNotFoundRequestResponse>({
        path: `/User/UserById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VersionList
     * @request GET:/User/Version
     * @secure
     */
    versionList: (params: RequestParams = {}) =>
      this.request<string, ProblemDetails>({
        path: `/User/Version`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name LoginCreate
     * @request POST:/User/Login
     * @secure
     */
    loginCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResult, string>({
        path: `/User/Login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name RefreshCreate
     * @request POST:/User/Refresh
     * @secure
     */
    refreshCreate: (data: RefreshRequest, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/User/Refresh`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name CreateUserCreate
     * @request POST:/User/CreateUser
     * @secure
     */
    createUserCreate: (data: CreateUser, params: RequestParams = {}) =>
      this.request<UserResponse, ApiBadRequestResponse | ProblemDetails>({
        path: `/User/CreateUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name PasswordResetCreate
     * @request POST:/User/PasswordReset
     * @secure
     */
    passwordResetCreate: (query?: { email?: string }, params: RequestParams = {}) =>
      this.request<OkResult, ProblemDetails | ApiNotFoundRequestResponse>({
        path: `/User/PasswordReset`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SubmitPasswordResetCreate
     * @request POST:/User/SubmitPasswordReset
     * @secure
     */
    submitPasswordResetCreate: (data: PasswordResetRequest, params: RequestParams = {}) =>
      this.request<IdentityResult, ProblemDetails | ApiNotFoundRequestResponse>({
        path: `/User/SubmitPasswordReset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateRolesCreate
     * @request POST:/User/UpdateRoles
     * @secure
     */
    updateRolesCreate: (data: UpdateUser, params: RequestParams = {}) =>
      this.request<OkResult, ApiBadRequestResponse | ProblemDetails | ApiNotFoundRequestResponse>({
        path: `/User/UpdateRoles`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  webhooks = {
    /**
     * No description
     *
     * @tags Webhooks
     * @name SubmitCreate
     * @request POST:/Webhooks/Submit
     * @secure
     */
    submitCreate: (data: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Webhooks/Submit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
