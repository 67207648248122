import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "@reach/dialog/styles.css";
import "@reach/menu-button/styles.css";
import { ConfirmProvider } from "./context/Confirm";
import { QueryWrapper } from "./QueryWrapper";
import { AuthProvider } from "./context/Auth";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfirmProvider>
        <AuthProvider>
          <QueryWrapper>
            <App />
          </QueryWrapper>
        </AuthProvider>
      </ConfirmProvider>
    </BrowserRouter>
    <ToastContainer
      enableMultiContainer
      containerId={"standard"}
      autoClose={3500}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      icon={false}
      style={{
        zIndex: 10000,
      }}
    />
    <ToastContainer
      className={"wide-toast"}
      enableMultiContainer
      containerId={"wide"}
      autoClose={3500}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      icon={false}
      style={{
        zIndex: 10000,
      }}
    />
  </React.StrictMode>
);
reportWebVitals();
