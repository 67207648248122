import {
  Api,
  BreedingListing,
  BreedingListingPaginationResult,
  BreedingRequest,
  BreedingResult,
  BreedingTransferMethod,
  CowDetail,
  CowListingPaginationResult,
  CowType,
  CowUpdateRequest,
  CreateUser,
  EmbryoDetail,
  EmbryoListingPaginationResult,
  EmbryoUpdateRequest,
  ExpenseListingPaginationResult,
  IdentityResult,
  LoginResult,
  OkResult,
  OptionGuidResponse,
  PasswordResetRequest,
  RecipBreedingDetail,
  RecipBreedingRequest,
  RecipDetail,
  RecipListingPaginationResult,
  RecipUpdateRequest,
  ReportListing,
  SortOrder,
  UpdateUser,
  UserAndRoles,
  UserResponse,
} from "../generatedTypes";
import { authStorage } from "./utils";

const dbApi = new Api({ baseUrl: process.env.REACT_APP_BACKEND_URL });

export async function getCows(queryParams?: {
  cowType?: CowType;
  minPurchaseAt?: string;
  maxPurchaseAt?: string;
  minPurchaseAmount?: number;
  maxPurchaseAmount?: number;
  minBornAt?: string;
  maxBornAt?: string;
  minSoldAt?: string;
  maxSoldAt?: string;
  minSoldAmount?: number;
  maxSoldAmount?: number;
  minProjectTotal?: number;
  maxProjectTotal?: number;
  minGrandTotal?: number;
  maxGrandTotal?: number;
  purchasedFrom?: string;
  soldTo?: string;
  damId?: string;
  sireId?: string;
  recipId?: string;
  page?: number;
  sizePerPage?: number;
  sortField?: string;
  sortOrder?: SortOrder;
  search?: string;
}): Promise<CowListingPaginationResult> {
  return await dbApi.cow
    .listingList(queryParams)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getRecips(queryParams?: {
  minPurchaseAt?: string;
  maxPurchaseAt?: string;
  minPurchaseAmount?: number;
  maxPurchaseAmount?: number;
  minBornAt?: string;
  maxBornAt?: string;
  minSoldAt?: string;
  maxSoldAt?: string;
  minSoldAmount?: number;
  maxSoldAmount?: number;
  minProjectTotal?: number;
  maxProjectTotal?: number;
  minGrandTotal?: number;
  maxGrandTotal?: number;
  purchasedFrom?: string;
  soldTo?: string;
  damId?: string;
  sireId?: string;
  recipId?: string;
  page?: number;
  sizePerPage?: number;
  sortField?: string;
  sortOrder?: SortOrder;
  search?: string;
  minTransferAt?: string;
  maxTransferAt?: string;
  minTransferCheckAt?: string;
  maxTransferCheckAt?: string;
  transferType?: BreedingTransferMethod;
  embryoId?: string;
  transferLocation?: string;
  isPregnant?: boolean;
}): Promise<RecipListingPaginationResult> {
  return await dbApi.cow
    .recipListingList(queryParams)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getCowDetails(queryParams: {
  id: string;
}): Promise<CowDetail | RecipDetail> {
  return await dbApi.cow
    .detailsDetail(queryParams.id)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getEmbryos(queryParams: {
  minFrozenAt?: string;
  maxFrozenAt?: string;
  minTransferAt?: string;
  maxTransferAt?: string;
  minTransferCheckAt?: string;
  maxTransferCheckAt?: string;
  tank?: string;
  birthStatus?: BreedingResult;
  transferType?: BreedingTransferMethod;
  minPurchaseAt?: string;
  maxPurchaseAt?: string;
  minPurchaseAmount?: number;
  maxPurchaseAmount?: number;
  minBornAt?: string;
  maxBornAt?: string;
  minSoldAt?: string;
  maxSoldAt?: string;
  minSoldAmount?: number;
  maxSoldAmount?: number;
  minProjectTotal?: number;
  maxProjectTotal?: number;
  minGrandTotal?: number;
  maxGrandTotal?: number;
  purchasedFrom?: string;
  soldTo?: string;
  damId?: string;
  sireId?: string;
  recipId?: string;
  page?: number;
  sizePerPage?: number;
  sortField?: string;
  sortOrder?: SortOrder;
  search?: string;
}): Promise<EmbryoListingPaginationResult> {
  return await dbApi.embryo
    .listingList(queryParams)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getEmbryoDetails(queryParams: {
  id: string;
}): Promise<EmbryoDetail> {
  return await dbApi.embryo
    .detailsDetail(queryParams.id)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getRecipDetails(queryParams: {
  id: string;
}): Promise<RecipDetail> {
  return await dbApi.cow
    .detailsDetail(queryParams.id)
    .then((res) => res.data as RecipDetail)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getBreedingLog(queryParams: {
  id: string;
  query: {
    page?: number;
    sizePerPage?: number;
    sortField?: string;
    sortOrder?: SortOrder;
    search?: string;
  };
}): Promise<BreedingListingPaginationResult> {
  return await dbApi.breeding
    .byCowIdDetail(queryParams.id, queryParams.query)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getCowEventLog(queryParams: {
  id: string;
  query: {
    page?: number;
    sizePerPage?: number;
    sortField?: string;
    sortOrder?: SortOrder;
    search?: string;
  };
}): Promise<ExpenseListingPaginationResult> {
  return await dbApi.expense
    .byCowIdDetail(queryParams.id, queryParams.query)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getEmbryoEventLog(queryParams: {
  id: string;
  query: {
    page?: number;
    sizePerPage?: number;
    sortField?: string;
    sortOrder?: SortOrder;
    search?: string;
  };
}): Promise<ExpenseListingPaginationResult> {
  return await dbApi.expense
    .byEmbryoIdDetail(queryParams.id, queryParams.query)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getPurchaseFromOptions(): Promise<string[]> {
  return await dbApi.options
    .purchasedFromList()
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getSoldToOptions(): Promise<string[]> {
  return await dbApi.options
    .soldToList()
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getTankOptions(): Promise<string[]> {
  return await dbApi.options
    .tanksList()
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getCowsListOptions(): Promise<OptionGuidResponse[]> {
  return await dbApi.options
    .cowsList()
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getEmbryosListOptions(): Promise<OptionGuidResponse[]> {
  return await dbApi.options
    .embryosList()
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function updateCowDetails(queryParams: {
  id: string;
  data: CowUpdateRequest;
}): Promise<CowDetail | RecipDetail> {
  return await dbApi.cow
    .updateCreate(queryParams.id, queryParams.data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function updateRecipDetails(queryParams: {
  id: string;
  data: RecipUpdateRequest;
}): Promise<RecipDetail> {
  return await dbApi.cow
    .updateRecipCreate(queryParams.id, queryParams.data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function updateBreedingEntry(queryParams: {
  id: string;
  data: BreedingRequest;
}): Promise<BreedingListing> {
  return await dbApi.breeding
    .updateEntryCreate(queryParams.id, queryParams.data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function updateRecipBreedingEntry(queryParams: {
  id: string;
  data: RecipBreedingRequest;
}): Promise<RecipBreedingDetail> {
  return await dbApi.breeding
    .updateRecipEntryCreate(queryParams.id, queryParams.data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function markOpen(queryParams: {
  id: string;
}): Promise<BreedingListing> {
  return await dbApi.cow
    .markOpenCreate(queryParams.id)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function updateEmbryoDetails(queryParams: {
  id: string;
  data: EmbryoUpdateRequest;
}): Promise<EmbryoDetail> {
  return await dbApi.embryo
    .updateCreate(queryParams.id, queryParams.data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function addOffspring(queryParams: {
  cowId: string;
  offspringId: string;
}): Promise<CowDetail> {
  return await dbApi.cow
    .addOffspringCreate(queryParams.cowId, {
      offspringId: queryParams.offspringId,
    })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function removeOffspring(queryParams: {
  cowId: string;
  offspringId: string;
}): Promise<CowDetail> {
  return await dbApi.cow
    .removeOffspringCreate(queryParams.cowId, {
      offspringId: queryParams.offspringId,
    })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export interface GetUsersResponse {
  userList: UserAndRoles[];
}

export async function getAllUsersList(): Promise<UserAndRoles[]> {
  return await dbApi.user
    .allUsersList()
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}
export async function passwordResetCreate(queryParams: {
  email: string;
}): Promise<OkResult> {
  return await dbApi.user
    .passwordResetCreate(queryParams)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function submitPasswordResetCreate(
  queryParams: PasswordResetRequest
): Promise<IdentityResult> {
  return await dbApi.user
    .submitPasswordResetCreate(queryParams)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function createUserCreate(
  queryParams: CreateUser
): Promise<UserResponse> {
  return await dbApi.user
    .createUserCreate(queryParams)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function updateRolesCreate(
  queryParams: UpdateUser
): Promise<OkResult> {
  return await dbApi.user
    .updateRolesCreate(queryParams)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export async function uploadGem(queryParams: {
  zipPackage: File;
}): Promise<void> {
  const data = new FormData();
  data.append("zipPackage", queryParams.zipPackage);

  return await fetch(`${process.env.REACT_APP_BACKEND_URL}/File/UploadGEM`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authStorage.getAccessToken()}`,
    },
    body: data,
  })
    .then(() => {
      return;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function login(queryParams: {
  username: string;
  password: string;
}): Promise<LoginResult> {
  return await dbApi.user
    .loginCreate({
      username: queryParams.username,
      password: queryParams.password,
    })
    .then((res) => ({
      token: res.data.token,
      refreshToken: res.data.refreshToken,
      userName: res.data.name,
      userId: res.data.userId,
    }))
    .catch((error) => {
      throw new Error(error);
    });
}

export async function refreshAccessToken(queryParams: {
  accessToken: string;
  refreshToken: string;
}): Promise<any> {
  return await fetch(`${process.env.REACT_APP_BACKEND_URL}/User/Refresh`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: queryParams.accessToken,
      refreshToken: queryParams.refreshToken,
    }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getReports(): Promise<ReportListing> {
  return await dbApi.report
    .getFinancialsList()
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
}

export interface HttpResponse<T> extends Response {
  error?: T;
  message?: string;
  errorDesc?: string;
}

export interface HttpError extends Error {
  errorDesc?: string;
}

export async function authFetch(endpoint: string, options?: any) {
  const loginTokens = authStorage.getLoginTokens();
  if (!loginTokens.id) {
    throw { message: "Unauthorized" };
  }

  return await fetch(endpoint, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: loginTokens.id,
      "Content-Type": "text/plain",
    },
  })
    .then((response: HttpResponse<string>) => {
      if (response.status === 401) {
        throw "error - CORS";
      } else if (!response.ok && response?.errorDesc) {
        throw response.errorDesc;
      } else if (!response.ok && response?.message) {
        throw response.message;
      } else if (!response.ok) {
        throw "Network Error";
      } else {
        return response.json();
      }
    })
    .catch((error: HttpError) => {
      if (error?.errorDesc) {
        throw { message: error.errorDesc };
      } else if (error?.message) {
        throw { message: error.message };
      } else {
        throw { message: error.toString() };
      }
    });
}
